import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Form,
  Button,
  FormControl,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { localDevelopmentPath } from "../tempText/env";

function Login() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const styleCenter = {
    textAlign: "center",
  };
  const handleClick = () => {
    setIsSubmitting(true);
    setIsButtonClicked(true);
  };
  const handleLogin = (event) => {
    // event.preventDefault();
    // 驗證使用者憑據
    // 如果驗證成功，設定使用者的登入狀態為已登入
    let postBody = {
      account: account,
      password: password,
    };

    // axios.post(`${localDevelopmentPath}login`, postBody).then((res) => {
    //   if (res.data.code == 401) {
    //     alert(res.data.msg);
    //     setIsSubmitting(false);
    //   } else if (res.data.code == 200) {
    //     sessionStorage.setItem("lavender", "admin");
    //     navigate("/private");
    //   }
    // });
    if (account === "Lavender378" && password == "Asdf1688") {
      sessionStorage.setItem("lavender", "admin");
      navigate("/private");
    }
  };
  useEffect(() => {
    // 執行其他必要的邏輯或設定
    // 在這裡可以根據需要調用 handleLogin 進行登入
    if (isButtonClicked) {
      handleLogin();
    }
  }, [isButtonClicked]);

  // if (isLoggedIn) {
  //   navigate("/private");
  // }

  return (
    <>
      <Container fluid className="h-100">
        <Row className="h-100 align-items-center">
          <Col className="d-flex justify-content-center">
            <Card style={{ width: "18rem", marginTop: "20px" }}>
              <Card.Body>
                {" "}
                <Form>
                  <Form.Group className="mb-3" controlId="formText">
                    <Form.Label>帳號</Form.Label>
                    <FormControl
                      type="text"
                      placeholder="輸入帳號"
                      onChange={(e) => {
                        setAccount(e.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Label>密碼</Form.Label>
                    <FormControl
                      type="password"
                      placeholder="輸入密碼"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      autoComplete="current-password"
                    />
                  </Form.Group>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      disabled={isSubmitting}
                      variant="outline-dark"
                      onClick={handleClick}
                    >
                      {isSubmitting ? "正在登入..." : "登入"}
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
