import React, { Component } from "react";
import { convertToRaw, EditorState } from "draft-js";
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Button, Modal } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { localDevelopmentPath } from "../tempText/env";
let backendPath = "https://lavenderbackend.onrender.com/";
const Draft = (props) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [text, setText] = useState();
  const { time } = props;
  let dataName = "";
  if (time === "day") {
    dataName = "🙌🏻 手工 🙌🏻";
  } else {
    dataName = "🚿 日洗 🚿";
  }
  // loading and success
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const localPath = localDevelopmentPath + time;
  const onEditorStateChange = function (editorState) {
    setEditorState(editorState);
    const { blocks } = convertToRaw(editorState.getCurrentContent());

    /*let text = blocks.reduce((acc, item) => {
      acc = acc + item.text;
      return acc;
    }, "");*/
    let text = editorState.getCurrentContent().getPlainText("\u0001");
    setText(text);
  };
  const handleSave = () => {
    setIsSubmitting(true);

    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    let text = "";
    // 轉換器
    if (rawContent.blocks.length > 1) {
      for (let i = 0; i < rawContent.blocks.length; i++) {
        text += rawContent.blocks[i].text + ",";
      }
    } else {
      text = rawContent.blocks[0].text;
    }
    let postText = { [time]: text };
    axios
      .post(localPath, postText)
      .then((res) => {
        console.log(res);
        setShowSuccess(true);
      })
      .catch((err) => {
        console.log("err:" + err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    // setTimeout(() => {
    //   setShowSuccess(true);
    //   setIsSubmitting(false);
    // }, 3000);
    console.log(rawContent);
    // onSave(rawContent);
  };
  const handleClose = () => {
    setShowSuccess(false);
  };

  return (
    <>
      <div class="row justify-content-center ">
        <div class="col-12 text-center">
          {<div style={{ height: "80px", overflow: "auto" }}>{text}</div>}
        </div>
      </div>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        mention={{
          separator: " ",
          trigger: "@",
          suggestions: [
            { text: "APPLE", value: "apple" },
            { text: "BANANA", value: "banana", url: "banana" },
            { text: "CHERRY", value: "cherry", url: "cherry" },
            { text: "DURIAN", value: "durian", url: "durian" },
            { text: "EGGFRUIT", value: "eggfruit", url: "eggfruit" },
            { text: "FIG", value: "fig", url: "fig" },
            { text: "GRAPEFRUIT", value: "grapefruit", url: "grapefruit" },
            { text: "HONEYDEW", value: "honeydew", url: "honeydew" },
          ],
        }}
      />
      <Button
        onClick={handleSave}
        disabled={isSubmitting}
        variant="outline-dark"
      >
        {isSubmitting ? "正在儲存..." : "儲存"}
      </Button>
      <Modal show={showSuccess} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{dataName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>儲存成功</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            關閉
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Draft;
