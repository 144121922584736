import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import "../fullscreen.css";

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="fullscreen-carousel">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./sample/20200208_KAIPAPARAZZI_7600.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./sample/Moon.jpg"
            alt="Second slide"
          />

          <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./sample/Moon.jpg"
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
    // <div class="container-fluid px-0">
    //   <div
    //     id="carouselExampleControls"
    //     class="carousel slide"
    //     data-ride="carousel"
    //   >
    //     <div class="carousel-inner bg-info" role="listbox">
    //       <div class="carousel-item active">
    //         <div class="d-flex align-items-center justify-content-center min-vh-100">
    //           <h1 class="display-1">ONE</h1>
    //         </div>
    //       </div>
    //       <div class="carousel-item">
    //         <div class="d-flex align-items-center justify-content-center min-vh-100">
    //           <h1 class="display-1">TWO</h1>
    //         </div>
    //       </div>
    //       <div class="carousel-item">
    //         <div class="d-flex align-items-center justify-content-center min-vh-100">
    //           <h1 class="display-1">THREE</h1>
    //         </div>
    //       </div>
    //     </div>
    //     <a
    //       class="carousel-control-prev"
    //       href="#carouselExampleControls"
    //       role="button"
    //       data-slide="prev"
    //     >
    //       <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    //       <span class="sr-only">Previous</span>
    //     </a>
    //     <a
    //       class="carousel-control-next"
    //       href="#carouselExampleControls"
    //       role="button"
    //       data-slide="next"
    //     >
    //       <span class="carousel-control-next-icon" aria-hidden="true"></span>
    //       <span class="sr-only">Next</span>
    //     </a>
    //   </div>
    // </div>
  );
}

export default ControlledCarousel;
