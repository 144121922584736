import Container from "react-bootstrap/Container";

function Link() {
  const style1 = {
    marginBottom: "30px",
  };
  const styleImage = {
    marginLeft: "20px",
  };
  return (
    <>
      <div>
        <Container style={style1}>
          <div class="row justify-content-center ">
            <div class="col-12 text-center">
              <p class="h4-text ">營業時間 08:00 ~ 05:00</p>
              <p class="h4-text ">聯絡方式 +Line好友</p>
              <a href="https://line.me/ti/p/RUc7NTsqAC" target="_blank">
                <img src="/line.png" className="line"></img>
              </a>
              <a
                href="https://t.me/lavender378"
                target="_blank"
                style={styleImage}
              >
                <img src="/telegram.png" className="line"></img>
              </a>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Link;
