import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import path from "../tempText/image.json";
import "../App.css";
import SquareImage from "./squreImage";
import axios from "axios";
import { useEffect, useState } from "react";
import { localDevelopmentPath } from "../tempText/env";
const host = "https://lavenderbackend.onrender.com/";
const localhost = "http://localhost:3030/";
const openImage = "image";
const getImagePath = localDevelopmentPath + openImage;
const Picture = ({ onImageClick }) => {
  const [imageData, setImageData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const style1 = {
    textAlign: "center",
    margin: "10px",
    backgroundColor: " #CA8EFF",
  };
  // get 要顯示的照片
  useEffect(() => {
    axios
      .get(getImagePath)
      .then((res) => {
        // let tempText = path.path;
        let tempText = res.data;
        setImageData(tempText);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err:" + err);
        setIsLoading(false);
      });
  }, []);

  const handleImageClick = (imageId) => {
    // console.log("click : " + imageId);
    onImageClick(imageId);
  };

  return (
    <>
      <div>
        {/* <button onClick={() => handleImageClick("123")}>Click me</button> */}
        <Row>
          {isLoading ? (
            // 如果数据正在加载，则显示加载状态
            <p>Loading...</p>
          ) : (
            // 数据加载完成后进行渲染
            <>
              {imageData.map((item) => (
                <Col xs={6} md={4}>
                  <SquareImage
                    imageUrl={item.path}
                    onClick={() => handleImageClick(item.path)}
                  />
                  <div style={style1}>{item.id}</div>
                </Col>
              ))}
            </>
          )}
        </Row>
      </div>
    </>
  );
};

export default Picture;
