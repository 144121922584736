import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Link from "./components/link";
import Day from "./components/day";
import Picture from "./components/picture";
import ControlledCarousel from "./components/controlledCarousel";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Draft from "./components/Draft";
import Login from "./components/login";
import BackCounter from "./components/backCounter";
// import { ReactFullscreenCarousel } from "react-fullscreen-carousel";

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [imgPath, setImgPath] = useState("./images/01.jpg");
  // 強制轉https
  // useEffect(() => {
  //   if (window.location.protocol !== "https:") {
  //     window.location.href = `https://${window.location.host}${window.location.pathname}`;
  //   }
  // }, []);
  const [maxHeight, setMaxHeight] = useState(window.innerHeight * 0.9);

  useEffect(() => {
    const handleResize = () => {
      const containerHeight = window.innerHeight * 0.8;
      setMaxHeight(containerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleImageClick = (imageId) => {
    setImgPath(imageId);
    // console.log(`Clicked image ID: ${imageId}`);
    setShowPopup(true);
  };

  const handleCloseClick = () => {
    setShowPopup(false);
  };
  const [clicked, setClicked] = useState(false);
  // full screen picture

  const handleClick = () => {
    if (clicked) {
      setClicked(false);
    } else {
      setClicked(true);
    }
  };
  // 判斷 render content
  const pathname = window.location.pathname;

  let content;

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <div className="title-bg">
                <Navbar className="bg-body-tertiary">
                  <Container>
                    <Navbar.Brand href="/">
                      <img
                        alt=""
                        src="./logo192.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                      />{" "}
                      小草spa
                    </Navbar.Brand>
                  </Container>
                </Navbar>
                <Link></Link>
                <Container>
                  <div className="separator"></div>
                  <Day></Day>
                  <div className="separator"></div>

                  <Picture onImageClick={handleImageClick}></Picture>
                </Container>
                {/* image popup */}
                {showPopup && (
                  <div className="popup-container">
                    <div className="popup-content">
                      <img
                        className="popup-image"
                        src={imgPath}
                        alt="Popup Image"
                        style={{ maxHeight: `${maxHeight}px` }}
                        onClick={handleCloseClick}
                      />
                      <span className="close-button" onClick={handleCloseClick}>
                        &times;
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </>
          }
        />
        <Route exact path="/login" element={<Login />} />
        <Route path="/private" element={<BackCounter />} />
      </Routes>
    </Router>

    // <div>{content}</div>
  );
}

export default App;
