import "../App.css";

const SquareImage = ({ imageUrl, onClick }) => {
  const imageStyle = {
    margin: "10px",
    backgroundImage: `url(${imageUrl})`,
  };

  return (
    <div className="square-image">
      <div className="image-content" style={imageStyle} onClick={onClick}></div>
    </div>
  );
};

export default SquareImage;
