import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import { Button, Modal } from "react-bootstrap";
import images from "../tempText/dailyCheckImage.json";
import { useEffect, useState } from "react";
import axios from "axios";
import { localDevelopmentPath } from "../tempText/env";

function DailyCheckImage() {
  const [checkboxData, setCheckboxData] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  let backendPath = "https://lavenderbackend.onrender.com/";
  const localPathCheck = localDevelopmentPath + "checkimage";
  const localPath = localDevelopmentPath + "image";
  let checkData = [];

  // get image count
  useEffect(() => {
    axios
      .get(localPathCheck)
      .then((res) => {
        const databaseData = res.data;
        // console.log(databaseData);

        databaseData.map((item) => {
          checkData.push(item.id);
        });
        // console.log("checkData數量: " + checkData.length);
        // 根据数据库数据生成相应数量的选中状态
        if (checkData.length == 0) {
          const initialCheckedState = checkData.map(() => false);
          // console.log(initialCheckedState);
          setCheckboxData(databaseData);
          setIsChecked(initialCheckedState);
        } else {
          const initialCheckedState = databaseData.map((item) =>
            item.open == "true" ? true : false
          );
          // console.log(initialCheckedState);
          setCheckboxData(databaseData);
          setIsChecked(initialCheckedState);
        }
      })
      .catch((err) => {});
  }, []);
  // handle checkBox change
  const handleCheckboxChange = (index) => {
    setIsChecked((prevChecked) => {
      const updatedCheckedState = [...prevChecked];
      updatedCheckedState[index] = !prevChecked[index];
      console.log("update check state : " + updatedCheckedState);
      return updatedCheckedState;
    });
  };
  const handleSaveClick = () => {
    console.log("====");
    console.log(checkboxData);
    setIsSubmitting(true);
    const selectedCheckboxes = checkboxData.filter(
      (_, index) => isChecked[index]
    );
    console.log("送出的東西");
    console.log(selectedCheckboxes);
    let postBody = { image: selectedCheckboxes };
    axios
      .post(localPath, postBody)
      .then((res) => {
        // console.log(res);
        setShowSuccess(true);
      })
      .catch((err) => {
        console.log("err:" + err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const handleClose = () => {
    setShowSuccess(false);
  };
  return (
    <>
      <div class="row justify-content-center ">
        <div class="col-12 text-center">
          <Form>
            {checkboxData.map((imageItem, index) => (
              <div key={imageItem.id} className="mb-3">
                <Form.Check
                  type={"inline-checkbox"}
                  id={imageItem.id}
                  inline
                  // checked={isChecked[index]}
                >
                  <Form.Check.Input
                    checked={isChecked[index]}
                    type={"checkbox"}
                    onChange={() => handleCheckboxChange(index)}
                  />
                  <Form.Check.Label>{imageItem.id}</Form.Check.Label>
                </Form.Check>
              </div>
            ))}
          </Form>
        </div>
      </div>
      <Button
        onClick={handleSaveClick}
        disabled={isSubmitting}
        variant="outline-dark"
      >
        {isSubmitting ? "正在儲存..." : "儲存"}
      </Button>
      <Modal show={showSuccess} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>每日顯示照片</Modal.Title>
        </Modal.Header>
        <Modal.Body>儲存成功</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>
            關閉
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DailyCheckImage;
